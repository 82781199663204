h1 {
	@apply text-5xl md:text-6xl mt-8 mb-6 font-semibold;
}

h2 {
	@apply text-4xl md:text-5xl mt-8 mb-6 font-semibold;
}

h3 {
	@apply text-3xl md:text-4xl mt-7 mb-5 font-semibold;
}

h4 {
	@apply text-2xl md:text-3xl mt-7 mb-5 font-medium;
}

h5 {
	@apply text-xl md:text-2xl mt-6 mb-4 font-medium;
}

h6 {
	@apply text-lg md:text-xl mt-6 mb-4 font-medium;
}